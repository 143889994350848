<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <Header :add_class="['header-auto-show']" />

    <Footer logged />
    <!-- New Page content-->
    <div class="page-content pb-0">
      <div data-card-height="" class="card">
        <!-- Email Activate-->
        <div
          v-if="!isVerified"
          class="card card-style shadow-0 bg-red-dark mb-1 mt-2"
        >
          <button
            class="btn accordion-btn color-white no-effect"
            data-bs-toggle="collapse"
            data-bs-target="#collapse4"
          >
            <i class=""></i>
            Email anda belum di verifikasi
            <i class="fa fa-angle-down font-10 accordion-icon"></i>
          </button>
          <div
            id="collapse4"
            class="collapse bg-theme"
            data-parent="#accordion-2"
          >
            <div class="pt-3 pb-3">
              <p class="mb-0">
                <span v-html="message"></span><br />
                Segera aktifkan akun anda dengan kilk link
                <a class="" href="#" @click="resendToken">ini</a>
              </p>
            </div>
          </div>
        </div>
        <!-- Email Activate-->
        <!-- Card profile -->
        <CardProfile
          v-if="profile.user"
          :profile="profile"
          :userType="userType"
          @menuOpen="menuOpen"
        />
        <!-- End Card profile -->

        <!-- Navigation profile -->
        <div class="content">
          <NavigationCompany v-if="userType == 'E'" @signOut="signOut" />
          <NavigationUser v-if="userType == 'J'" @signOut="signOut" />
        </div>
        <!-- Navigation profile -->
      </div>
    </div>
    <!-- New Page content ends here-->
    <UploadImage @close="menuClose" @refresh="getProfile" :profile="profile" />

    <!-- Install Prompt for Android -->
    <div
      id="menu-install-pwa-android"
      class="menu menu-box-bottom rounded-m"
      data-menu-height="380"
      data-menu-effect="menu-parallax"
    >
      <img
        class="mx-auto mt-4 rounded-m"
        src="app/icons/icon-128x128.png"
        alt="img"
        width="90"
      />
      <h4 class="text-center mt-4 mb-2">Appkit on your Home Screen</h4>
      <p class="text-center boxed-text-xl">
        Install Appkit on your home screen, and access it just like a regular
        app. It really is that simple!
      </p>
      <div class="boxed-text-l">
        <a href="#" class="pwa-install mx-auto btn btn-m font-600 bg-highlight"
          >Add to Home Screen - Off In Demo</a
        >
        <a
          href="#"
          class="pwa-dismiss close-menu btn-full mt-3 pt-2 text-center text-uppercase font-600 color-red-light font-12"
          >Maybe later</a
        >
      </div>
    </div>

    <!-- Install instructions for iOS -->
    <div
      id="menu-install-pwa-ios"
      class="menu menu-box-bottom rounded-m"
      data-menu-height="350"
      data-menu-effect="menu-parallax"
    >
      <div class="boxed-text-xl top-25">
        <img
          class="mx-auto mt-4 rounded-m"
          src="app/icons/icon-128x128.png"
          alt="img"
          width="90"
        />
        <h4 class="text-center mt-4 mb-2">Appkit on your Home Screen</h4>
        <p class="text-center ms-3 me-3">
          Install Appkit on your home screen, and access it just like a regular
          app. Open your Safari menu and tap "Add to Home Screen".
        </p>
        <a
          href="#"
          class="pwa-dismiss close-menu btn-full mt-3 text-center text-uppercase font-900 color-red-light opacity-90 font-110"
          >Maybe later</a
        >
      </div>
    </div>
    <div
      id="notif-input-data"
      class="menu menu-box-modal bg-highlight rounded-m"
      data-menu-height="310"
      data-menu-width="350"
      style="display: block; width: 350px; height: 310px"
    >
      <h1 class="text-center mt-4">
        <i
          class="fa fa-3x fa-info-circle scale-box color-white shadow-xl rounded-circle"
        ></i>
      </h1>
      <p class="boxed-text-l color-white opacity-70">
        Ayo lengkapi Profil anda<br />
        Untuk memudahkan anda ketika melamar kerja
      </p>
      <router-link
        :to="{ name: 'UserProfile' }"
        class="close-menu btn btn-m btn-center-l button-s shadow-l rounded-s text-uppercase font-600 bg-white color-black"
        >Lengkapi Sekarang!</router-link
      >
      <a
        @click="menuClose"
        class="boxed-text-l text-uppercase font-300 color-white"
        >Lewati</a
      >
    </div>

    <!-- Main Menu-->
    <div
      id="notif-input-data-profile"
      class="menu menu-box-modal bg-highlight rounded-m"
      data-menu-height="310"
      data-menu-width="350"
      style="display: block; width: 350px; height: 310px"
    >
      <h1 class="text-center mt-4">
        <i
          class="fa fa-3x fa-info-circle scale-box color-white shadow-xl rounded-circle"
        ></i>
      </h1>
      <p class="boxed-text-l color-white opacity-70">
        Ayo lengkapi Profil anda<br />
        Untuk memudahkan anda ketika melamar kerja
      </p>
      <a
        class="close-menu btn btn-m btn-center-l button-s shadow-l rounded-s text-uppercase font-600 bg-white color-black"
        >Lanjutkan!</a
      >
      <a
        @click="menuClose"
        class="boxed-text-l text-uppercase font-300 color-white"
        >Lewati</a
      >
    </div>
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { checkPWA } from "../isPWA.js";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";
/*import "../assets/code/scripts/custom.js";*/
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

import NavigationCompany from "@/components/dashboard/NavigationCompany.vue";
import NavigationUser from "@/components/dashboard/NavigationUser.vue";
import CardProfile from "@/components/dashboard/CardProfile.vue";
import UploadImage from "@/components/dashboard/UploadImage.vue";

import { getToken } from "firebase/messaging";
export default {
  name: "Dashboard",
  components: {
    Header,
    Footer,
    NavigationCompany,
    NavigationUser,
    CardProfile,
    UploadImage,
  },
  inject: ["getMasterData", "getApply", "messaging"],
  data() {
    return {
      profile: {},
      userType: this.$store.state.account.userType,
      isVerified: false,
      master_data: {},
      apiUrl: "/api/v1/auth/user/",
      isLoading: true,
      message: `Jika email anda belum di verifikasi, akun anda di anggap tidak aktif, anda tidak dapat melamar pekerjaan `,
      errors: [],
      isAuthenticated: this.$store.state.isAuthenticated,
    };
  },
  beforeMount() {
    this.getAccountInfo();
  },
  mounted() {
    this.getMasterData();
    init_template();
    if (this.userType === "J") {
      this.apiUrl = "/api/v1/job-seeker/profile/";
      this.getProfile();
    } else if (this.userType === "E") {
      this.apiUrl = "/api/v1/employer/company/profile/";
      this.message = `Jika email anda belum di verifikaasi, akun anda di anggap tidak aktif, anda di tidak dapat menambahkan lowongan maupun project`;
      this.getProfile();
    }

    if (this.isAuthenticated) {
      console.log("is isAuthenticated");
      getToken(this.messaging)
        .then((currentToken) => {
          if (currentToken) {
            this.sendDeviceId(currentToken);
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
    }

    this.checkPWA();
  },
  methods: {
    checkPWA,
    menuClose,
    menuOpen,
    async getAccountInfo() {
      await axios
        .get("/api/v1/auth/user/")
        .then((response) => {
          if (response.data) {
            const user_type = response.data.user_type;

            const accountData = {
              userType: user_type,
              isVerified: response.data.is_verified,
              email: response.data.email,
            };

            this.$store.commit("addAccount", accountData);
            this.isVerified = response.data.is_verified;
            this.$store.state.account.isVerified = this.isVerified;
          }
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }

            console.log(
              "response data getApply : " + JSON.stringify(error.response)
            );
          } else if (error.message) {
            console.log("error :" + JSON.stringify(error));
          }
        });
    },
    sendDeviceId(token) {
      axios
        .post("/api/v1/auth/device_id/", {
          device_id: token,
        })
        .then((response) => {
          console.log("response : ", response);
        })
        .catch((error) => {
          if (error.response) {
            /*when refresh token expired*/
            console.log("response error:", JSON.stringify(error.response));
          } else if (error.message) {
            console.log("error message :", JSON.stringify(error.message));
          }
          this.$store.commit("removeUserInformation");
          this.$router.push("/sign-in");
        });
    },
    signOut() {
      axios.defaults.headers.common["Authorization"] = "";

      this.$store.commit("removeUserInformation");
      this.$router.push("/sign-in");
    },
    /*resend email verification*/
    async resendToken() {
      await axios
        .get("api/v1/auth/resend-token/")
        .then((response) => {
          if (response.data.message === "success") {
            this.signOut();
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            console.log(JSON.stringify(error));
          }
        });
    },

    checkProfile() {
      try {
        if (!this.profile.get_resume) {
          this.menuOpen("notif-input-data");
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getProfile() {
      await axios
        .get(this.apiUrl)
        .then((response) => {
          console.log(response.data.length);
          if (response.data.length === 1) {
            this.profile = response.data[0];
            if (this.userType === "J") {
              const dataApplyJob = this.$store.state.apply;
              console.log("apply data commit: ", dataApplyJob);
              if (!dataApplyJob.length) {
                console.log('adding data apply...')
                this.$store.commit("addApply", response.data[0].apply_user);
              }
              this.checkProfile();
            }
            setTimeout(() => {
              this.isLoading = false;
            }, 500);
          } else if (response.data.length === 0) {
            /*redirect if dosn't has user profile*/
            this.userType === "E"
              ? this.$router.push({ name: "CompanyProfile" })
              : this.$router.push({ name: "UserProfile" });
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log("response error detail ", error.response);
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log(JSON.stringify(error));
          }
        });
    },
  },
};
</script>
