<template>
  <div
    id="upload-photo"
    class="menu menu-box-modal rounded-m bg-theme"
    data-menu-width="300"
  >
    <div class="content">
      <div class="menu-title mb-2">
        <h4 class="color-highlight">{{ titleUpload }}</h4>
        <a href="#" @click="menuClose" class="close-menu"
          ><i class="fa fa-times-circle"></i
        ></a>
      </div>
      <div class="divider mt-n2"></div>

      <div class="text-center mb-3">
        <img
          v-if="userProfile === 'user'"
          :src="photoUrl"
          class="rounded-sm"
          width="80"
        />
        <img
          v-if="userProfile === 'company'"
          :src="logoUrl"
          class="rounded-sm"
          width="80"
        />
      </div>

      <input
        type="file"
        ref="fileInput"
        id="file-upload"
        class="shadow-s rounded-s"
        accept="image/*"
        @input="onInput"
      />
      <p class="font-9 color-red-light font-100 mt-2 mb-0">
        *maksimal ukuran photo 5mb, untuk hasil lebih baik 84x84 px
      </p>
      <a
        @click="upload"
        href="#"
        class="
          close-menu
          btn btn-full
          gradient-blue
          font-13
          btn-m
          font-600
          mt-3
          mb-3
          rounded-s
        "
        >{{ btnName }}</a
      >
    </div>
  </div>
  <div
    id="toastUpload"
    class="toast toast-tiny toast-top bg-blue-dark"
    data-bs-delay="2000"
    data-bs-autohide="true"
  >
    {{ succesMsg }}
  </div>
</template>
<script>
import axios from "axios";
import { Toast } from "bootstrap";
export default {
  name: "UploadImage",
  emits: ["close", "refresh"],
  props: ["profile"],
  data() {
    return {
      titleUpload: "Photo Profile",
      photoUrl: this.profile.get_photo,
      logoUrl: this.profile.get_logo,
      photo: "",
      userProfile: "company",
      apiUrl: "/api/v1/employer/company/profile/logo/",
      btnName: "Ubah Logo",
      succesMsg: "upload berhasil",
    };
  },
  watch: {
    profile() {
      if ("get_photo" in this.profile) {
        this.photoUrl = this.profile.get_photo;
        this.userProfile = "user";
        this.apiUrl = "/api/v1/job-seeker/profile/photo-profile/";
        this.btnName = "Ubah Photo";
      }
      if ("get_logo" in this.profile) {
        this.logoUrl = this.profile.get_logo;
        this.titleUpload = "Logo";
      }
    },
  },
  mounted() {},
  methods: {
    menuClose() {
      this.$emit("close");
    },
    onInput(event) {
      this.photo = event.target.files[0];
      let fileInput = this.$refs.fileInput;
      let imgFile = fileInput.files;
      if (imgFile && imgFile[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.photoUrl = e.target.result;
          this.logoUrl = e.target.result;
        };
        reader.readAsDataURL(imgFile[0]);
      }
    },
    upload() {
      try {
        const fd = new FormData();
        if (this.userProfile === "user") {
          fd.append("photo", this.photo, this.photo.name);
        }
        if (this.userProfile === "company") {
          fd.append("logo", this.photo, this.photo.name);
        }
        axios
          .post(this.apiUrl, fd, (uploadEvent) => {
            console.log(
              "progress:" +
                Math.round((uploadEvent.loaded / uploadEvent.total) * 100 + "%")
            );
          })
          .then((response) => {
            this.$emit("refresh");
            this.menuClose();
            var notificationToast = document.getElementById("toastUpload");
            var notificationToast = new Toast(notificationToast);
            notificationToast.show();
          })
          .catch((error) => {
            this.$emit("refresh");
            this.menuClose();
            console.log(error);
          });
      } catch (error) {
        this.$emit("refresh");
        this.menuClose();
        console.log(error);
      }
    },
  },
};
</script>
