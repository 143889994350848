<template>
  <div
    class="card card-style mx-3 mt-4 bg-highlight"
    data-card-height="180"
    style="height: 180px"
  >
    <div class="d-flex justify-content-between card-top px-3 pt-3">
      <div>
        <h5 class="color-white line-height-xs">
          <span v-if="userType === 'J'"
            >{{ profile.first_name }} {{ profile.last_name }}</span
          >
          <span v-if="userType === 'E'">{{ profile.business_name }}</span>
        </h5>
        <h5 class="font-13 font-400 color-white line-height-xs"></h5>
      </div>
      <a
        class="border-0" href="#" data-gallery="gallery-a"
        v-if="userType === 'J'"
        @click="menuOpen('upload-photo')"
      >
        <div
          class="card bg-transparent"
        >
          <img
            :src="photoUrl"
            typeUser="J"
            @error="imageUrlAlt"
            class="card-img rounded-m preload-img shadow-xl img-fluid entered loaded"
            alt=""
            style="height: 84px; width: 84px; object-fit: cover;"
          />
          <div class="card-img-overlay p-0 d-flex align-items-end" v-if="userBadge === 1">
            <div
              class="w-100 text-center opacity-90"
              style="background-color: #663ba0 !important; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px;"
            >
              <span class="fw-bold text-white" style="font-size: x-small">#OPENTOWORK</span>
            </div>
          </div>
        </div>
      </a>
      <a
        class="border-0" href="#" data-gallery="gallery-a"
        v-if="userType === 'E'"
        @click="menuOpen('upload-photo')"
      >
        <div
          class="card bg-transparent"
        >
          <img
            :src="logoUrl"
            typeUser="E"
            @error="imageUrlAlt"
            class="card-img rounded-m preload-img shadow-xl img-fluid entered loaded"
            style="height: 84px; width: 84px; object-fit: cover;"
          />
          <div
            class="card-img-overlay p-0 d-flex align-items-end"
            v-if="userBadge === 1"
          >
            <div
              class="w-100 text-center opacity-90"
              style="background-color: #fb6e50 !important; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px;"
            >
              <span class="fw-bold text-white" style="font-size: x-small">#HIRING</span>
            </div>
          </div>
        </div>
<!--        <img-->
<!--          @click="menuOpen('upload-photo')"-->
<!--          @error="imageUrlAlt"-->
<!--          v-if="userType == 'J'"-->
<!--          :src="photoUrl"-->
<!--          typeUser="J"-->
<!--          alt="img"-->
<!--          class="-->
<!--            rounded-m-->
<!--            preload-img-->
<!--            shadow-xl-->
<!--            img-fluid-->
<!--            entered-->
<!--            loaded-->
<!--            text-end-->
<!--          "-->
<!--          style="height: 84px; width: 84px; object-fit: cover"-->
<!--        />-->
<!--        <img-->
<!--          v-if="userType == 'E'"-->
<!--          @click="menuOpen('upload-photo')"-->
<!--          @error="imageUrlAlt"-->
<!--          :src="logoUrl"-->
<!--          typeuser="E"-->
<!--          alt="img"-->
<!--          class="-->
<!--            rounded-m-->
<!--            preload-img-->
<!--            shadow-xl-->
<!--            img-fluid-->
<!--            entered-->
<!--            loaded-->
<!--            text-end-->
<!--          "-->
<!--          style="height: 84px; width: 84px; object-fit: cover"-->
<!--        />-->
      </a>
    </div>
    <div @click="toAccount" class="card-bottom ps-3 pb-2">
      <h5 class="font-14 color-white line-height-xs">
        {{ profile.user.email }}
      </h5>
      <h5 class="font-14 color-white line-height-xs">
        {{ profile.user.mobile_no }}
      </h5>
    </div>
    <div class="card-bottom pe-3 mb-3">
      <h5
        v-if="userType === 'J'"
        class="font-14 color-white line-height-xs text-end"
      >
        {{ profile.current_job_title }}
      </h5>
      <!-- Edit Change Password       <router-link
        :to="{ name: 'Account' }"
        href=""
        class="float-end color-white mb-2 font-14"
      >
        <i class="far fa-edit">Edit</i></router-link
      >
-->
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "CardProfile",
  props: ["profile", "userType"],
  emits: ["menuOpen"],
  data() {
    return {
      apiEndpoint: process.env.VUE_APP_ROOT_API_NODE,
      userBadge: 0,
      photoUrl:'',
      logoUrl: '',
    };
  },
  mounted() {
    /*this.getStatusBadge();*/
    if (this.profile.get_photo) {
      this.photoUrl = axios.defaults.baseURL + this.profile.get_photo;
    }
    if (this.profile.get_logo) {
      this.logoUrl = axios.defaults.baseURL + this.profile.get_logo;
    }
  },
  methods: {
    menuOpen(data) {
      this.$emit("menuOpen", data);
    },
    imageUrlAlt(event) {
      if (event.target.attributes[1].nodeValue === "J") {
        event.target.src = "/images/defaults/default_image.png";
      }
      if (event.target.attributes[1].nodeValue === "E") {
        event.target.src = "/images/defaults/default_logo.png";
      }
    },
    toAccount() {
      this.$router.push({ name: "Account" });
    },
    async getStatusBadge() {
      this.errors = [];
      await axios
        .get("user/get-badge", {
          baseURL: this.apiEndpoint,
        })
        .then(({ data }) => {
          this.userBadge = data.data.status_badge
        })
        .catch((error) => {
          this.errors = [];
          if (error.response) {
            console.log(
              "response data : " + JSON.stringify(error.response.data)
            );
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log("error :", JSON.stringify(error));
          }
        });
    },
  },
};
</script>
