export const checkPWA = () => {
  console.log("checkPWA");
  // Detecting Mobile OS
  const isMobile = {
    Android() {
      return navigator.userAgent.match(/Android/i);
    },
    iOS() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    any() {
      return isMobile.Android() || isMobile.iOS();
    },
  };

  const pwaName = "PasJob"; // Local Storage Names for PWA
  //const pwaLocation = "service-worker.js";
  //const pwaScope = "/";
  //const pwaRemind = 1; // Days to re-remind to add to home

  const checkPWA = document.getElementsByTagName("html")[0];
  if (!checkPWA.classList.contains("isPWA")) {
    console.log("!isPWA");

    // Trigger Install Prompt for Android
    const pwaWindows = document.querySelectorAll(
      "#menu-install-pwa-android, #menu-install-pwa-ios"
    );

    if (pwaWindows.length) {
    console.log("true querySelectorAll menu-install-pwa");
      if (isMobile.Android()) {
        console.log('is android')
        const showInstallPrompt = () => {
          console.log("show install prompt");
          setTimeout(() => {
            if (!window.matchMedia("(display-mode: fullscreen)").matches) {
              console.log("Triggering PWA Window for Android");
              document
                .getElementById("menu-install-pwa-android")
                .classList.add("menu-active");
              document
                .querySelectorAll(".menu-hider")[0]
                .classList.add("menu-active");
            }
          }, 3500);
        };
        var deferredPrompt;
        window.addEventListener("beforeinstallprompt", (e) => {
          e.preventDefault();
          deferredPrompt = e;
          showInstallPrompt();
        });
        const pwaInstall = document.querySelectorAll(".pwa-install");
        pwaInstall.forEach((el) =>
          el.addEventListener("click", (e) => {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
              if (choiceResult.outcome === "accepted") {
                console.log("Added");
              } else {
                localStorage.setItem(`${pwaName}-PWA-Timeout-Value`, now);
                localStorage.setItem(
                  `${pwaName}-PWA-Prompt`,
                  "install-rejected"
                );
                setTimeout(() => {
                  if (
                    !window.matchMedia("(display-mode: fullscreen)").matches
                  ) {
                    document
                      .getElementById("menu-install-pwa-android")
                      .classList.remove("menu-active");
                    document
                      .querySelectorAll(".menu-hider")[0]
                      .classList.remove("menu-active");
                  }
                }, 50);
              }
              deferredPrompt = null;
            });
          })
        );
        window.addEventListener("appinstalled", (evt) => {
          document
            .getElementById("menu-install-pwa-android")
            .classList.remove("menu-active");
          document
            .querySelectorAll(".menu-hider")[0]
            .classList.remove("menu-active");
        });
      }
      // Trigger Install Guide iOS
      if (isMobile.iOS()) {
        if (
          localStorage.getItem(`${pwaName}-PWA-Prompt`) != "install-rejected"
        ) {
          setTimeout(() => {
            if (!window.matchMedia("(display-mode: fullscreen)").matches) {
              console.log("Triggering PWA Window for iOS");
              document
                .getElementById("menu-install-pwa-ios")
                .classList.add("menu-active");
              document
                .querySelectorAll(".menu-hider")[0]
                .classList.add("menu-active");
            }
          }, 3500);
        }
      }
    }
  }
  checkPWA.setAttribute("class", "isPWA");
};
