<template>
  <div class="list-group list-custom-small mb-5">
    <router-link :to="{ name: 'UserProfile' }">
      <i
        class="
          fa fa-pen
          font-14
          rounded-sm
          shadow-xl
          bg-blue-dark
          color-white
          bg-highlight
        "
      ></i>
      <span class="font-500">Ubah Profil</span>
      <i class="fa fa-angle-right"></i>
    </router-link>

    <router-link :to="{ name: 'InviteFriend' }">
      <i
        class="
          fa fa-user-friends
          font-14
          rounded-sm
          shadow-xl
          bg-blue-dark
          color-white
          bg-highlight
        "
      ></i>
      <span class="font-500">Undang Teman</span>
      <i class="fa fa-angle-right"></i>
    </router-link>

    <router-link :to="{ name: 'Apply', params: { job_type: 'vacancy' } }">
      <i
        class="
          fa fa-clipboard
          font-14
          rounded-sm
          shadow-xl
          bg-blue-dark
          color-white
          bg-highlight
        "
      ></i>
      <span class="font-500">Pengajuan Lowongan</span>
      <i class="fa fa-angle-right"></i>
    </router-link>

    <router-link :to="{ name: 'Apply', params: { job_type: 'project' } }">
      <i
        class="
          fa fa-clipboard
          font-14
          rounded-sm
          shadow-xl
          bg-blue-dark
          color-white
          bg-highlight
        "
      ></i>
      <span class="font-500">Pengajuan Freelance</span>
      <i class="fa fa-angle-right"></i>
    </router-link>

    <router-link :to="{ name: 'UploadCV' }">
      <i
        class="
          fa fa-chevron-circle-up
          font-14
          rounded-sm
          shadow-xl
          bg-blue-dark
          color-white
          bg-highlight
        "
      ></i>
      <span class="font-500">CV</span>
      <i class="fa fa-angle-right"></i>
    </router-link>

    <router-link :to="{ name: 'PoinSaya' }">
      <i
        class="
          fa fa-star
          font-14
          rounded-sm
          shadow-xl
          text-white
        "
        style="background-color: #8CC152"
      ></i>
      <span class="font-500">Poin Saya</span>
      <i class="fa fa-angle-right"></i>
    </router-link>

    <router-link :to="{ name: 'JobExperience' }">
      <i
        class="
          fa fa-user
          font-14
          rounded-sm
          shadow-xl
          bg-blue-dark
          color-white
          bg-highlight
        "
      ></i>
      <span class="font-500">Pengalaman Kerja</span>
      <i class="fa fa-angle-right"></i>
    </router-link>

    <router-link :to="{ name: 'UserSkill' }">
      <i
        class="
          fa fa-wrench
          font-14
          rounded-sm
          shadow-xl
          bg-blue-dark
          color-white
          bg-highlight
        "
      ></i>
      <span class="font-500">Skill</span>
      <i class="fa fa-angle-right"></i>
    </router-link>

    <a href="https://landbot.online/v3/H-1614658-Y6GMJ5J2YESMJ88G/index.html">
      <i
        class="
          fa fa-mobile
          font-14
          rounded-sm
          shadow-xl
          bg-blue-dark
          color-white
          bg-highlight
        "
      ></i>
      <span class="font-500">Tanya Pasjob</span>
      <i class="fa fa-angle-right"></i>
    </a>

    <router-link :to="{ name: 'Bookmark' }">
      <i
        class="
          fa fa-bookmark
          font-14
          rounded-sm
          shadow-xl
          bg-blue-dark
          color-white
          bg-highlight
        "
      ></i>
      <span class="font-500">Lowongan Disimpan</span>
      <i class="fa fa-angle-right"></i>
    </router-link>

    <router-link :to="{ name: 'PrivacyPolicy' }">
      <i
        class="
          fa
          font-14
          fa-bars
          rounded-sm
          shadow-xl
          bg-blue-dark
          color-white
          bg-highlight
        "
      ></i>
      <span class="font-500">Kebijakan Privasi</span>
      <i class="fa fa-angle-right"></i>
    </router-link>
    <a @click="signOut" href="#">
      <i
        class="
          fa
          font-14
          fa-sign-out-alt
          rounded-sm
          shadow-xl
          bg-blue-dark
          color-white
          bg-highlight
        "
      ></i>
      <span class="font-500">Keluar</span>
      <i class="fa fa-angle-right"></i>
    </a>
  </div>
</template>

<script>
export default {
  name: "NavigationUser",
  emits: ["signOut"],
  methods: {
    signOut() {
      this.$emit("signOut");
    },
  },
};
</script>
